import {ColorModeScript} from '@chakra-ui/react';
import React, {StrictMode} from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import TimeAgo from 'javascript-time-ago';
import { HelmetProvider } from 'react-helmet-async';

import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <StrictMode>
        <ColorModeScript/>
        <HelmetProvider>
            <App/>
        </HelmetProvider>
    </StrictMode>
);
