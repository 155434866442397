import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import { imageBaseUrl } from "../../baseUrl";

let logo = `${imageBaseUrl}credo-img.png`;

const Loader = () => {
  return (
    <Flex
      h="100vh"
      w="100vw"
      alignItems="center"
      justify="center"
      bg={"#0000008a"}
    >
      <NewLoader>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left="0"
          right={0}
          bottom={0}
          top={0}
          margin="auto"
        >
          <Image src={logo} alt="logo" w="40px" />{" "}
        </Box>
        <div
          class="loader"
          style={{
            "--b": "5px",
            "--c": "#00e2ff",
            width: "80px",
            "--n": "15",
            "--g": "5deg",
          }}
        ></div>
      </NewLoader>
    </Flex>
  );
};

export default Loader;

export const NewLoader = styled.div`
  position: relative;
  .loader {
    --b: 10px; /* border thickness */
    --n: 10; /* number of dashes*/
    --g: 10deg; /* gap  between dashes*/
    --c: red; /* the color */

    width: 100px; /* size */
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px; /* get rid of bad outlines */
    background: conic-gradient(#0000, var(--c)) content-box;
    --_m: /* we use +/-1deg between colors to avoid jagged edges */ repeating-conic-gradient(
        #0000 0deg,
        #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
        #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
      ),
      radial-gradient(
        farthest-side,
        #0000 calc(98% - var(--b)),
        #000 calc(100% - var(--b))
      );
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: load 1s infinite steps(var(--n));
  }
  @keyframes load {
    to {
      transform: rotate(1turn);
    }
  }
`;
