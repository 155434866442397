/* eslint-disable no-unused-vars */
import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import theme from '@chakra-ui/theme';
import ReactGA from 'react-ga';
import {Toaster} from 'react-hot-toast';
import Loader from 'pages/LoaderComponent/Loader';

const AllRoutes = React.lazy(() => import('./routes'));

function App() {

  const TRACKING_ID = 'G-3X5G95SYL4';
  ReactGA.initialize(TRACKING_ID);

  const toastOpt = {
    style: {
      borderRadius: '3px',
      background: '#1F845A',
      color: '#fff',
      fontSize: '.9rem',
    },
    success: {
      iconTheme: {
        secondary: '#1F845A',
        primary: 'white',
      },
      style: {
        color: '#fff',
        fontSize: '.9rem',
        borderRadius: '3px',
        background: '#1F845A',
      },
    },

    error: {
      style: {
        color: '#fff',
        fontSize: '.9rem',
        borderRadius: '3px',
        background: '#F97066',
      },
      iconTheme: {
        secondary: '#F97066',
        primary: 'white',
      },
    },
  };

  return (
      <ChakraProvider theme={theme}>
        <React.Suspense fallback={<Loader/>}>
          <Toaster
              containerStyle={{zIndex: '10000000'}}
              toastOptions={toastOpt}
          />
          <AllRoutes/>
        </React.Suspense>
      </ChakraProvider>
  );
}

export default App;
